<template>
  <div
    style="height:100%"
  >
    <LineChart
      v-if="data.source !== 'energy'"
      :chart-data="chartDataUplink"
      :options="chartOptionsUplink"
      :styles="chartContainerStyle"
    />

    <BarChart
      v-if="data.source === 'energy'"
      :chart-data="chartDataEnergy"
      :options="chartOptionsEnergy"
      :styles="chartContainerStyle"
    />
  </div>
</template>

<script>
import {
  getHours, addHours, format, parseISO,
} from 'date-fns';
import { underscoreToSpace } from '../../../utils/utils';
import LineChart from '../../../components/LineChart.vue';
import BarChart from '../../../components/BarChart.vue';
import WidgetMixin from '../../../mixins/WidgetMixin';

export default {
  name: 'ChartWidget',
  components: { LineChart, BarChart },
  mixins: [WidgetMixin],

  data() {
    return {
      chartContainerStyle: {
        width: '100%',
        height: '100%',
        position: 'relative',
      },
    };
  },

  computed: {
    payloadKey() {
      return this.data.payload_keys ? this.data.payload_keys[0] : null;
    },

    energies() {
      if (this.data.source === 'energy') {
        return this.deviceData.payloads.slice().reverse();
      }
      return [];
    },

    chartDataEnergy() {
      return {
        labels: this.energies.map((d) => d.starts_at),
        datasets: [
          {
            label: 'WH',
            backgroundColor: 'rgba(92,184,92,0.3)',
            borderColor: 'rgb(92,184,92)',
            borderWidth: 2,
            maxBarThickness: 40,
            data: this.energies.map((energy) => energy.cumulative_value),
          },
        ],
      };
    },

    chartOptionsEnergy() {
      return {
        responsive: true,
        responsiveAnimationDuration: 500,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: `Energy Meter (${this.data.energy.interval})`,
        },
        scales: {
          xAxes: [{
            // scaleLabel: {
            //   display: false,
            //   labelString: 'Time',
            // },
            offset: true,
            ticks: {
              callback: (tick) => {
                const { interval } = this.data.energy;
                const datetime = parseISO(tick);

                let xTick;
                if (interval === 'hourly') {
                  xTick = getHours(datetime);
                } else if (interval === 'daily') {
                  xTick = format(datetime, 'dd MMM');
                } else if (interval === 'monthly') {
                  xTick = format(datetime, 'MMMM');
                } else {
                  xTick = format(datetime, 'dd MMM YY / HH:mm');
                }
                return xTick;
              },
            },
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'WH',
            },
            ticks: {
              beginAtZero: true,
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            title: (tooltipItem) => {
              // `tooltipItem` is an object containing properties such as
              // the dataset and the index of the current item
              const { interval } = this.data.energy;
              const datetime = parseISO(this.chartDataEnergy.labels[tooltipItem[0].index]);

              if (interval === 'hourly') {
                const tooltipDateTime = format(datetime, 'EEEE dd MMM yyyy');
                const rangeHour = `${format(datetime, 'HH:mm')} - ${format(addHours(datetime, 1), 'HH:mm')}`;
                return `${tooltipDateTime} / ${rangeHour}`;
              } if (interval === 'daily') {
                return format(datetime, 'EEEE dd MMM yyyy');
              } if (interval === 'monthly') {
                return format(datetime, 'MMMM yyyy');
              }
              return format(datetime, 'dd MMM YY / HH:mm');
            },
          },
        },
        hover: {
          mode: 'index',
          intersect: false,
        },
      };
    },

    chartDataUplink() {
      // dataset for line chart / timeseries
      return {
        datasets: [
          {
            label: underscoreToSpace(this.payloadKey),
            backgroundColor: 'rgba(0,0,0,0)',
            borderColor: '#5cb85c',
            pointBackgroundColor: '#5cb85c',
            borderWidth: 2,
            pointRadius: 0,
            data: this.createDatasetTimeSeries(this.deviceData.payloads, this.payloadKey),
            pointHoverRadius: 5,
          },
        ],
      };
    },

    chartOptionsUplink() {
      return {
        responsive: true,
        responsiveAnimationDuration: 500,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            type: 'time',
            display: true,
            gridLines: {
              // display: false,
              drawOnChartArea: false,
            },
            time: {
              unit: 'hour',
              unitStepSize: 1,
              displayFormats: {
                hour: 'HH:mm',
                min: '00:00',
                max: '23:59',
              },
            },
            scaleLabel: {
              display: false,
              labelString: 'Time',
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 5,
              maxRotation: 0,
              minRotation: 0,
            },
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: this.$options.filters.parsePayloadKey(this.payloadKey).unit ?? '',
            },
          }],
        },
        elements: {
          line: {
            tension: 0,
          },
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label: (tooltipItem) => {
              const label = this.payloadKey;
              const { value } = tooltipItem;
              const { unit } = this.$options.filters.parsePayloadKey(this.payloadKey) ?? '';

              return `${label} : ${value} ${unit}`;
            },
          },
        },
        hover: {
          mode: 'index',
          intersect: false,
        },
      };
    },
  },

  methods: {
    createDatasetTimeSeries(payloadsArr = [], selectedKey) {
      // create x,y data for line chart
      return payloadsArr.map((item) => ({
        x: new Date(item.datetime),
        y: item[selectedKey],
      }));
    },
  },

};
</script>
